<template>
  <div>
    <textarea v-bind="{ ...$props, ...$attrs }" class="textarea" @input="_emit($event.target.value)" />
  </div>
</template>

<script>
export default {
  name: 'ciam-textarea',
  inheritAttrs: false,
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {
    _emit(value) {
      this.$emit('input', value);
    },
  },
  mounted() {},
  beforeDestroy() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-input {
  @apply block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}
</style>
