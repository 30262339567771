<template>
  <ciam-page :title-suffix="$t('pageTitles.incident')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <div class="wrapperTitle">
            <div class="logo-title mt-1">
              <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em">
                <path d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24h16v33.3C119.6 157.2 32 252.4 32 368H480c0-115.6-87.6-210.8-200-222.7V112h16c13.3 0 24-10.7 24-24s-10.7-24-24-24H256 216zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z" />
              </svg>
            </div>
            <div class="ml-2 right-title">
              <ciam-text>
                {{ $t('organization.reportIncident.header') }}
              </ciam-text>
            </div>
          </div>
        </template>
        <template v-slot:subtitle>Please fill out the form below to report your <u><a target="_blank" href="https://www.cloud-iam.com/en/sla#severity-levels">P1-Critical incident</a></u>. Once submitted, our on-call team will be immediately notified and will respond to you promptly.</template>
      </ciam-card-header>

      <ciam-card-content>
        <ciam-card-content-lines>
          <ciam-card-content-line>
            <template v-slot:label>
              <ciam-text
              ><b>{{ $t('organization.reportIncident.form.incidentTitle') }}</b>
              </ciam-text>
            </template>
          </ciam-card-content-line>
          <ciam-card-content-line>
            <template v-slot:label>
              <ciam-text
              >{{ $t('organization.reportIncident.form.deploymentImpacted') }}
              </ciam-text>
            </template>
            <template v-slot:content>
              <ciam-select :options="deployments" v-model="form.deploymentImpacted" @input="onDeploymentImpactedChange()" data-e2e="deployment-select"></ciam-select>
            </template>
          </ciam-card-content-line>
          <ciam-card-content-line>
            <template v-slot:label>
              <ciam-text>
                {{ $t('organization.reportIncident.form.deploymentVersion') }}
              </ciam-text>
            </template>
            <template v-slot:content>
              <div class="bg-gray-200 rounded gray-bg-version" data-e2e="keycloak-version">
                {{ `${form.keycloakVersion}` }}
              </div>

            </template>
          </ciam-card-content-line>
          <ciam-card-content-line>
            <template v-slot:label>
              <ciam-text
              >{{ $t('organization.reportIncident.form.describeIssue') }}
              </ciam-text>
            </template>
            <template v-slot:content>
              <ciam-textarea
                data-e2e="trigger-incident-description"
                :class="['border-2', form.description.length == 0 ? 'border-red-500 rounded' : null]"
                type="description"
                aria-required="true"
                placeholder="Please provide a detailed description of the incident you are experiencing to help our technical team assist you effectively."
                v-model="form.description">
              </ciam-textarea>
              <div class="mt-6 wrapperCheckBox">
                <input data-e2e="accept-checkbox"
                       type="checkbox"
                       v-model="form.acceptForm"
                       class="left mt-1" />
                <span class="ml-5 right">
                  {{ $t('organization.reportIncident.form.acceptTerms') }} <u><a @click="redirect('TicketList')">support ticket.</a></u>
                </span>

              </div>
              <ciam-button data-e2e="report-incident-button" :disabled="isFormInvalid()" @click="doReport()" class="mt-12 danger rounded">{{ $t('organization.reportIncident.form.button') }}</ciam-button>
              <div class="mt-8">
                <b>Note</b>
                <ul>
                  <li><i>As soon as you complete the form and click on 'Report Incident,' our on-call team will be immediately alerted.</i></li>
                  <li><i>Make sure all mandatory fields are filled out accurately to ensure a prompt response.</i></li>
                  <li><i>Documentation page about <u><a href="https://documentation.cloud-iam.com/references/on-call-team.html" target="_blank">trigger on-call</a></u> feature.</i></li>
                </ul>
              </div>
            </template>
          </ciam-card-content-line>

        </ciam-card-content-lines>
      </ciam-card-content>
      <Promised :promise="loadingPromise">
        <template v-slot:pending>
          <ciam-loader class="py-4"></ciam-loader>
        </template>
        <template v-slot:rejected="error">
          <ciam-alert :title="error.message" :description="error.message" :type="AlertStatus.ALERT"></ciam-alert>
        </template>
        <template>
          <div v-if="!form.enabled">
            <template>
              <ciam-alert
                data-e2e="upsell-message"
                :title="$t('upsell.title')"
                :description="getUpsellAlertDescription()"
                :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')"
                :href="getUpsellAlertHref()"
              />
            </template>
          </div>
        </template>
      </Promised>
    </ciam-card>
    <ciam-card class="mt-12">
      <ciam-alert
        data-e2e="warning-banner"
        v-if="form.enabled"
        :title="$t('organization.reportIncident.warningTitle')"
        :description="$t('organization.reportIncident.warningMessage')"
        :type="AlertStatus.WARNING_HREF"
        href-text="SLAs web page."
        href="https://www.cloud-iam.com/en/sla"
      />
    </ciam-card>
  </ciam-page>
</template>

<script>
import CiamTextarea from '@/components/CiamTextarea.vue';
import CiamAlert from '@/components/CiamAlert.vue';
import i18n from '@/i18n';
import OrganizationService from '@/pages/organizations/OrganizationService';
import { Notification } from 'vue-notifyjs';
import { routeNames } from '@/router';
import { AlertStatus } from '@/components/CiamAlert';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import CiamSelect from '@/components/CiamSelect';
import CiamButton from '@/components/CiamButton';
import DeploymentService from '../deployments/DeploymentService';

export default {
  name: 'ReportIncident',
  components: {
    CiamTextarea,
    CiamAlert,
    CiamSelect,
    CiamButton,
  },
  data() {
    return {
      loadingPromise: new Promise(() => {
      }), // loading state by default

      /**
       * @type {null|string} null if organization_id is not specified, a string uuid otherwise
       */
      organization_id: null,
      problem: null,
      AlertStatus: AlertStatus,
      deploymentList: [],
      form: {
        enabled: false,
        description: '',
        acceptForm: false,
        deploymentImpacted: null,
        keycloakVersion: null,
        entireDescription: null,
        deploymentName: null,
      },
      deployments: [],
    };
  },
  mounted() {
    this.unsubscriber = this.$watch(
      '$route.query.organization_id',
      function(new_organization_id) {
        if (this.organization_id === new_organization_id || !new_organization_id) {
          // do nothing
          return;
        }

        this.organization_id = new_organization_id;
        this.loadingPromise = OrganizationService.canTriggerOnCall(new_organization_id).then(
          (enabled) => (this.form.enabled = enabled),
        );
      },
      {
        immediate: true,
      },
    );
    this.getDeploymentsByOrganizationId();
  },
  beforeDestroy() {
    this.unsubscriber();
  },
  methods: {
    isFormInvalid() {
      return !(this.form.acceptForm && this.form.deploymentImpacted && this.form.description);
    },
    async onDeploymentImpactedChange() {
      if (this.form && this.form.deploymentImpacted) {
        this.getKeycloakVersion(this.form.deploymentImpacted);
        const selectedDeployment = this.deployments.find(depl => depl.value === this.form.deploymentImpacted);
        if (selectedDeployment) {
          this.form.deploymentName = selectedDeployment.label;
        }
      }
    },
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(i18n.t(`organization.reportIncident.featureName`));
    },

    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(i18n.t(`organization.reportIncident.featureName`));
    },
    async getDeploymentsByOrganizationId() {
      const allDeployments = [];
      let currentPage = 0;
      let totalPages = 1;

      while (currentPage < totalPages) {
        const response = await DeploymentService.getAll({
          sortField: 'name',
          sortOrder: 'asc',
          organization_id: this.$route.query.organization_id,
          dedicated: true,
          page: currentPage
        });
        totalPages = response.page.totalPages;
        allDeployments.push(...response._embedded.deployments);
        currentPage++;
      }

      this.deployments = allDeployments.map(depl => ({
        value: depl.id,
        label: depl.name,
      }));

      this.deploymentList = allDeployments;
    },
    redirect(page) {
      this.$router.push({ name: routeNames[page], query: { organization_id: this.$route.query.organization_id } });
    },
    async getKeycloakVersion(deploymentId) {
      try {
        const deploymentToFindStats = this.deploymentList.find(depl => depl.id === deploymentId);
        const statistics = await DeploymentService.getStatistics(deploymentToFindStats);
        this.form.keycloakVersion = statistics.version;
      } catch (any) {
        this.form.keycloakVersion = 'unknown';
      }
    },
    doReport() {
      this.form.entireDescription = `Incident triggered on deployment: ${this.form.deploymentName}\nWith keycloak version ${this.form.keycloakVersion}\n${this.form.description}`;
      OrganizationService.reportIncident(this.organization_id, this.form.entireDescription)
        .then(() => {
          Notification.notify({
            message: i18n.t('organization.reportIncident.reportSuccess'),
            type: 'info',
          });

          this.$router
            .push({
              name: routeNames.OrganizationDetails,
              query: {
                organization_id: this.organizationId,
              },
              params: { id: this.organizationId },
            })
            .catch(() => {
            });
        })
        .catch((problem) => {
          this.problem = problem;
        });
    },
  },
};
</script>
<style>
.ciam-card-content-line {
  border-style: none !important;
}

li {
  list-style-type: disc;
}

.right {
  flex: 1;
}

.wrapperCheckBox {
  display: flex;
}

.gray-bg-version {
  width: 20%;
  height: 130%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.on-call-logo {
  width: 10%;
}

.right-title {
  flex: 1;
}

.wrapperTitle {
  display: flex;
}
</style>